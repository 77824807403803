import { OnInit, Component, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";

import { ZoomMtg } from "@zoomus/websdk";
import { LocalStorage } from "./localStorage";
import { DataTransferObject } from "./DataTransferObject";
import { ToastrService } from "ngx-toastr";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  signature = "";
  apiKey = "";
  meetingNumber = 0;
  role = 0;
  topicname: string = "";
  disabledStart: boolean = false;
  disabledJoin: boolean = false;
  disabledEnd: boolean = true;
  attendance: boolean = false;
  Present: boolean = false;
  Absent: boolean = false;
  loader: boolean = false;
  selectedItem: any = { sectionid: 0 };
  attendancetoolbar: boolean = false;
  topnavbutton: boolean = false;

  leaveUrl = "https://live.edap.com.pk/";
  // leaveUrl = "http://localhost:4200/";
  userName = "";
  userEmail = "";
  passWord = "";
  apiSecret: string;
  typeid: any;
  topicdtlid: any;
  participantid: number;
  presentParticipants: any[] = [];
  sectiontabs: any = {};
  absentParticipants: any[] = [];
  tabs: any = {};
  absentstd: any[] = [];
  host: any = {};
  img: any;
  stdattd: any = {};
  clientlogo: any;
  attbtn: boolean = false;
  constructor(
    public httpClient: HttpClient,
    @Inject(DOCUMENT) document,
    private router: Router,
    private localStorage: LocalStorage,
    private activated: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.routeEvent(this.router);
  }

  ngOnChanges() { }

  ngDoCheck() {
    this.typeid = Number(this.localStorage.get("calltype"));
    this.topicname = this.localStorage.get("topicname");
    this.clientlogo = this.localStorage.get("EntityLogo");
  }

  ngOnInit() {
    this.localStorage.clear();
    if (Number(this.typeid) === 0) {
      this.routeEvent(this.router);
    }
    this.attendance = false;
  }

  ngAfterViewInit() {
    if (Number(this.typeid) === 0) {
      this.routeEvent(this.router);
    }
    this.typeid = Number(this.localStorage.get("calltype"));
    this.topicname = this.localStorage.get("topicname");
  }

  routeEvent(router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        let arr = e.url.split("?");
        if (arr.length >= 2) {
          var tokenSplit = arr[1].split("=&");
          if (tokenSplit.length >= 2) {
            for (let index = 0; index < tokenSplit.length; index++) {
              const element = tokenSplit[index].split(",");
              if (element && element.length >= 2 && element[0] && element[1]) {
                let data = element[1]
                  .replace(/%2F/g, "/")
                  .replace(/%20/g, " ")
                  .replace("=", "");
                if (data.match("%7B%") === null) {
                  localStorage.setItem(element[0], data);
                }
              }
            }
          }
        }
      }
    });
  }

  async startMeeting() {
    if (!this.disabledStart) {
      this.toastr.info("Meeting will be start", "Start");
      this.topnavbutton = true;
      await this.Start();
      this.disabledStart = true;
      this.disabledEnd = false;
    }
    this.topicdtlid = this.localStorage.get("topicdtlid");

    if (
      this.topicdtlid &&
      this.topicdtlid != "" &&
      this.topicdtlid != undefined
    ) {
      this.attbtn = true;
    }
  }

  zoomStart() {
    document.getElementById("zmmtg-root").style.display = "block";

    this.signature = this.localStorage.get("signature");
    this.meetingNumber = Number(this.localStorage.get("meetingNumber"));
    this.passWord = this.localStorage.get("password");
    this.apiKey = this.localStorage.get("apiKey");
    this.apiSecret = this.localStorage.get("apiSecret");
    this.role = Number(this.localStorage.get("role"));
    this.userName = this.localStorage.get("nametitle");

    ZoomMtg.inMeetingServiceListener("onUserJoin", function (data) {
      debugger
      ZoomMtg.putOnHold({
        userId: data.userId,
        hold: false
      });
      console.log(data);
    });

    ZoomMtg.inMeetingServiceListener("onUserLeave", function (data) {
      debugger
      console.log(data);
    });

    ZoomMtg.inMeetingServiceListener("onUserIsInWaitingRoom", function (data) {
      debugger
      console.log(data);
    });


    ZoomMtg.inMeetingServiceListener("onMeetingStatus", function (data) {
      debugger
      console.log(data);
    });

    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      disableInvite: true,

      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: this.signature,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          apiKey: this.apiKey,
          userEmail: this.userEmail,
          passWord: this.passWord,

          success: (success) => {
            console.log(success);
            ZoomMtg.record({
              record: true,
            });
            ZoomMtg.getAttendeeslist({});
            ZoomMtg.getCurrentUser({
              success: function (res) {
                console.log("success getCurrentUser", res.result.currentUser);
              },
            });
          },
          error: (error) => {
            console.log(error);
          },
        });

        ZoomMtg.record({
          record: true,
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  async Start() {
    let response: any;
    let meetingid = Number(this.localStorage.get("meetingid"));
    let participantid = Number(this.localStorage.get("participantid"));

    let url =
      this.localStorage
        .get("OnlyUrl")
        .concat("api/")
        .concat("joinmeeting/v2/")
        .concat("StartzoomMeeting/") +
      meetingid +
      "/" +
      participantid +
      "/" +
      1;
    this.startloader();
    await this.httpClient
      .get<DataTransferObject>(url, this.getheader())
      .toPromise()
      .then((x) => {
        Object.keys(x.Data).forEach((name) => {
          const value = x.Data[name];
          this.localStorage.set(name, value);

          if (x && x.IsSuccess) {
            this.attendance = true;
            this.stoploader();
          }
        });

        this.zoomStart();
      })
      .catch((x) =>
        x.error && x.error.Errors && x.error.Errors[0]
          ? this.toastr.error(x.error.Errors[0].Description, "Start meeting")
          : this.toastr.error(x.error)
      );
    this.stoploader();
  }

  async endMeeting() {
    // if (!this.disabledEnd) {
    this.topnavbutton = false;
    await this.endMeet();
    // }
  }

  leavemeeting() {
    ZoomMtg.leaveMeeting({});
    this.localStorage.clear();
    window.close();
  }

  async JoinMeeting() {
    let response: any;
    let meetingid = Number(this.localStorage.get("meetingid"));
    this.participantid = Number(this.localStorage.get("participantid"));
    this.typeid = JSON.parse(this.localStorage.get("calltype"));

    if (Number(this.typeid) !== 2) {
      let url =
        this.localStorage
          .get("OnlyUrl")
          .concat("api/")
          .concat("joinmeeting/v2/")
          .concat("ParticipantStatus/") +
        this.participantid +
        "/" +
        meetingid +
        "/" +
        1057 +
        "/" +
        1041;

      await this.httpClient
        .get<DataTransferObject>(url, this.getheader())
        .toPromise()
        .then((x) => {
          Object.keys(x.Data).forEach((name) => {
            const value = x.Data[name];
            this.localStorage.set(name, value);
          });

          this.zoomStart();
        })
        .catch((x) => {
          x.error && x.error.Errors && x.error.Errors[0]
            ? this.toastr.error(x.error.Errors[0].Description, "Participants")
            : this.toastr.error(x.error);
        });
    } else {
      this.participantid = Number(this.localStorage.get("Studentid"));

      let url =
        this.localStorage
          .get("OnlyUrl")
          .concat("api/")
          .concat("joinmeeting/v2/")
          .concat("ParticipantStatus/") +
        this.participantid +
        "/" +
        meetingid +
        "/" +
        1057 +
        "/" +
        1039;

      await this.httpClient
        .get<DataTransferObject>(url, this.getheader())
        .toPromise()
        .then((x) => {
          Object.keys(x.Data).forEach((name) => {
            const value = x.Data[name];
            this.localStorage.set(name, value);
          });

          this.zoomStart();
        })
        .catch((x) => {
          x.error && x.error.Errors && x.error.Errors[0]
            ? this.toastr.error(x.error.Errors[0].Description, "Participants")
            : this.toastr.error(x.error);
        });
    }
  }

  async endMeet() {
    let response: any;
    let meetingid = Number(this.localStorage.get("meetingid"));
    let participantid = Number(this.localStorage.get("participantid"));

    let url =
      this.localStorage
        .get("OnlyUrl")
        .concat("api/")
        .concat("joinmeeting/v2/")
        .concat("updateMeetingStatus/") +
      meetingid +
      "/" +
      79;

    await this.httpClient
      .get<DataTransferObject>(url, this.getheader())
      .toPromise()
      .then((x) => {
        ZoomMtg.endMeeting({});
        localStorage.clear();
        window.close();
      })
      .catch((x) => {
        x.error && x.error.Errors && x.error.Errors[0]
          ? this.toastr.error(x.error.Errors[0].Description, "End meeting")
          : this.toastr.error(x.error);
      });
  }

  getheader() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.localStorage.get("token"),
        "Access-Control-Allow-Origin": "*",
      }),
    };

    return httpOptions;
  }

  check: boolean = false;
  async particaptelist() {
    if (this.check === false) {
      this.check = true;

      var element = document.getElementById("attendancemodal");
      element.classList.add("show-stddtl");

      var element = document.getElementById("attendancemodal");
      element.classList.remove("hide-stddtl");
    } else if (this.check === true) {
      this.check = false;

      var element = document.getElementById("attendancemodal");
      element.classList.remove("show-stddtl");

      var element = document.getElementById("attendancemodal");
      element.classList.add("hide-stddtl");
    }
  }

  async studentattendance(item) {
    this.stdattd = item;
  }

  async saveAttendance(item, attendanceid) {
    await this.save(item, attendanceid);
    await this.getactiveParticipant();
  }

  async getactiveParticipant() {
    let imgurl = this.localStorage.get("OnlyUrl");
    let policybasemarkattendance = this.localStorage.get("markattendance");

    if (policybasemarkattendance && policybasemarkattendance !== "undefined") {
      this.attendancetoolbar = true;
    } else {
      this.attendancetoolbar = false;
    }
    let meetingid = Number(this.localStorage.get("meetingid"));
    let url =
      this.localStorage
        .get("OnlyUrl")
        .concat("api/")
        .concat("joinmeeting/v2/")
        .concat("ActiveParticipants/") + meetingid;
    //zeeshan

    this.startloader();
    await this.httpClient
      .get<DataTransferObject>(url, this.getheader())
      .toPromise()
      .then((x) => {
        if (x && x.IsSuccess) {
          if (x.Data && x.Data.length > 0) {
            //  this.absentstd = x.Data;
            this.tabs = x;

            this.host = x.Data[0].HostInfo;

            if (this.tabs.Data) {
              this.menuClick(this.tabs.Data[0]);
            }
          }

          // this.absentParticipants = this.absentstd.filter(a => a.statusid === 2);
          //this.presentParticipants = this.absentstd.filter(a => a.statusid === 1);

          if (this.host) {
            this.host.image = this.host
              ? imgurl + "Images/Actual/" + this.host.image
              : "assets/images/hostimg.jpg";
          }
          if (this.absentParticipants && this.absentParticipants.length > 0) {
            this.Absent = true;
          } else {
            this.Absent = false;
          }
          if (this.absentParticipants) {
            this.absentParticipants.forEach((element) => {
              element.image = element.image
                ? imgurl + "Images/Actual/" + element.image
                : "assets/images/user-img.png";
            });
          }
          if (this.presentParticipants && this.presentParticipants.length > 0) {
            this.Present = true;
          } else {
            this.Present = false;
          }
          if (this.presentParticipants) {
            this.presentParticipants.forEach((element) => {
              element.image = element.image
                ? imgurl + "Images/Actual/" + element.image
                : "assets/images/user-img.png";
            });
          }

          this.stoploader();
        }
      })
      .catch((x) => {
        x.error && x.error.Errors && x.error.Errors[0]
          ? this.toastr.error(
            x.error.Errors[0].Description,
            "Active Participants"
          )
          : this.toastr.error(x.error);
      });

    this.stoploader();

    this.Attendeeslist();
    this.CurrentUser();
  }

  async save(item, attendanceid) {
    let body: any = {};
    body.Data = [];
    let Data: any = {};

    Data.studentid = item.studentid;
    Data.courseid = item.courseid;
    Data.sectionid = item.sectionid;
    Data.atnactivityid = attendanceid;

    Data.atndate = new Date();
    Data.entityid = Number(this.localStorage.get("selectedEntity"));

    body.Data.push(Data);

    let url = this.localStorage
      .get("OnlyUrl")
      .concat("api/SMStuAttendance/")
      .concat("V2/SaveAllAttendance");
    this.startloader();
    await this.httpClient
      .post<DataTransferObject>(url, body, this.getheader())
      .toPromise()
      .then((x) => {
        // this.toastr.success("Attendance successfully marked.", "Attendance");

        this.stoploader();
      })
      .catch((x) =>
        x.error && x.error.Errors && x.error.Errors[0]
          ? this.toastr.error(x.error.Errors[0].Description, "Attendance")
          : this.toastr.error(x.error)
      );
    this.stoploader();
  }

  async startloader() {
    this.loader = true;
  }
  async stoploader() {
    this.loader = false;
  }

  async allstudentattendance(typeid) {
    let allstudentatn: any = {};
    //  if (typeid == 1) {

    this.tabs.Data[0].StudentsList.forEach(async (element) => {
      allstudentatn.studentid = element.studentid;
      allstudentatn.courseid = element.courseid;
      allstudentatn.sectionid = element.sectionid;
      allstudentatn.atnactivityid = typeid;

      await this.save(allstudentatn, typeid);
    });
    this.toastr.success("Attendance successfully marked.", "Attendance");
    await this.getactiveParticipant();
  }

  //check: boolean = false;

  sectionwisestudent: any = [];
  menuClick(item) {
    this.selectedItem = item;

    // this.tabs.Data.forEach(element => {
    this.sectionwisestudent = this.tabs.Data.filter(
      (a) => a.sectionid === this.selectedItem.sectionid
    );

    // });
  }

  async allmute() {
    ZoomMtg.muteAll({
      muteAll: true,
    });
  }


  async Attendeeslist() { }
  async CurrentUser() { }
}
